import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import HomePage from "./pages/HomePage";
import RootLayout from "./pages/RootLayout";
// import InfoPage from "./pages/InfoPage";
// import DetailPage from "./pages/DetailPage";
import { lazy, Suspense } from "react";

const InfoPage = lazy(() => import('./pages/InfoPage'));
const DetailPage = lazy(() => import('./pages/DetailPage'));

function App() {
  const router = createBrowserRouter([
    {
      path: "/:lang",
      element: <RootLayout />,
      children: [
        {
          path: "",
          element: <HomePage />,
        },
        {
          path: ":info",
          element: <Suspense  fallback={ <div className="main main-home">Loading...</div>}><InfoPage /></Suspense>,
        },
        {
          path: ":info/:detail",
          element: <Suspense  fallback={ <div className="main main-home">Loading...</div>}><DetailPage /></Suspense>,
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/pl" />, // Redirect to the default language ("/pl" in this case)
    },
  ]);

  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
