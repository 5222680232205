import React, { useContext } from "react";
import { motion } from "framer-motion";
import { NavLink, useParams } from "react-router-dom";
import { PageContext } from "../store/context";


const MobileNav = (props) => {
  const pageCtx = useContext(PageContext);
  const { lang } = useParams();
  const isPolish = lang === "pl";

  return (
    <>
      <motion.ul
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="mobile-nav"
      >
        <NavLink
          to="portfolio"
          onClick={() => {pageCtx.hideMobileNav(); }}
          className="mobile-nav__item"
        >
          portfolio
        </NavLink>
        <NavLink
          to={isPolish ? "obrazy" : "paintings"}
          onClick={pageCtx.hideMobileNav}
          className="mobile-nav__item"
        >
          {isPolish ? "obrazy" : "paintings"}
        </NavLink>
        <NavLink
          to={isPolish ? "rzeki" : "rivers"}
          onClick={pageCtx.hideMobileNav}
          className="mobile-nav__item"
        >
          {isPolish ? "rzeki" : "rivers"}
        </NavLink>
        <NavLink
          to={isPolish ? "drzewa" : "trees"}
          onClick={pageCtx.hideMobileNav}
          className="mobile-nav__item"
        >
          {isPolish ? "drzewa" : "trees"}
        </NavLink>
        <NavLink
          to={isPolish ? "omnie" : "aboutme"}
          onClick={pageCtx.hideMobileNav}
          className="mobile-nav__item"
        >
          {isPolish ? "o mnie" : "about me"}
        </NavLink>
        <NavLink
          to={isPolish ? "kontakt" : "contact"}
          onClick={pageCtx.hideMobileNav}
          className="mobile-nav__item"
        >
          {isPolish ? "kontakt" : "contact"}
        </NavLink>
        <NavLink
          to={isPolish ? "publikacje" : "publications"}
          onClick={pageCtx.hideMobileNav}
          className="mobile-nav__item"
        >
          {isPolish ? "publikacje" : "publications"}
        </NavLink>
      
      </motion.ul>
    </>
  );
};

export default MobileNav;
