import React from "react";
import LanguageButton from "./LanguageButton";
import { Link, useParams } from "react-router-dom";
import MainNav from "./MainNav";

const DesktopHeader = ({
  isPolish,
  switchToEnglish,
  switchToPolish,
}) => {
  const {lang} = useParams();
  const url = `/${lang}`;

  return (
    <>
      <Link className="header-title" to={url} >
        <h3>Cecylia Malik</h3>
      </Link>

      <MainNav />

      <LanguageButton
        isPolish={isPolish}
        onSwitchToEnglish={switchToEnglish}
        onSwitchToPolish={switchToPolish}
      />
    </>
  );
};

export default DesktopHeader;
