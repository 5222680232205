import { Outlet } from "react-router-dom";
import { useParams } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";
import { PageContext } from "../store/context";
import { useState } from "react";

const RootLayout = () => {
  const [toggleNav, setToggleNav] = useState();
  const [yUp, setYUp] = useState(0);
  const [yDown, setYDown] = useState(0);
  const [rotateLeft, setRotateLeft] = useState(0);
  const [rotateRight, setRotateRight] = useState(0);

  const params = useParams();
  const page = params.info;

  let classes = "background";

  if (page) {
    classes += " background-overlay";
  }

  function handleShowNav() {
    setToggleNav(true);
    handleAnimationStart();
  }

  function handleCloseNav() {
    setToggleNav(false);
    handleAnimationStart();
  }

  function handleAnimationStart () {
    if (rotateLeft === -45 || rotateRight === 45){
      setRotateLeft(0);
      setRotateRight(0);
      setYUp(0);
      setYDown(0);
      return;
    }
    setRotateLeft(-45);
    setYUp(-5);
    setRotateRight(45);
    setYDown(5);
    return;
  }

  const ctxValue = {
    isMobileNav: toggleNav,
    showMobileNav: handleShowNav,
    hideMobileNav: handleCloseNav,
    handleAnimationOfToggleButton: handleAnimationStart,
    yUp,
    yDown,
    rotateLeft,
    rotateRight,
  };

  return (
    <>
      <div className={classes}></div>
      <div className={page && "background-overlay__top"}></div>
      <div className="root">
        <PageContext.Provider value={ctxValue}>
          <Header />
          <Outlet />
          <Footer />
        </PageContext.Provider>
      </div>
    </>
  );
};

export default RootLayout;
