import React from 'react';
import LanguageButton from './LanguageButton';
import ToggleButton from './ToggleButton';
import { Link, useParams } from 'react-router-dom';

const MobileHeader = ({isPolish, switchToEnglish, switchToPolish, toggleMobileNavHandler}) => {

  const {lang} = useParams();
  const url = `/${lang}`;

  return (
    <>
    <LanguageButton
    isPolish={isPolish}
    onSwitchToEnglish={switchToEnglish}
    onSwitchToPolish={switchToPolish}
  />

  <Link className="header-title" to={url}>
    <h3>Cecylia Malik</h3>
  </Link>


  <ToggleButton onToggleMobileNavHandler={toggleMobileNavHandler} />
  
  </>
  )
}

export default MobileHeader;
