import React from "react";
import { NavLink, useParams } from "react-router-dom";

const MainNav = (props) => {
  const { lang } = useParams();
  const isPolish = lang === "pl";

  return (
    <>
      <div className="main-nav">
        <nav className="main-nav__items">
          <NavLink
            to="portfolio"
            onClick={props.onClick}
            className="main-nav__item"
          >
            portfolio
          </NavLink>
          <NavLink
            to={isPolish ? "obrazy" : "paintings"}
            onClick={props.onClick}
            className="main-nav__item"
          >
            {isPolish ? "obrazy" : "paintings"}
          </NavLink>
          <NavLink
            to={isPolish ? "rzeki" : "rivers"}
            onClick={props.onClick}
            className="main-nav__item"
          >
            {isPolish ? "rzeki" : "rivers"}
          </NavLink>
          <NavLink
            to={isPolish ? "drzewa" : "trees"}
            onClick={props.onClick}
            className="main-nav__item"
          >
            {isPolish ? "drzewa" : "trees"}
          </NavLink>
          <NavLink
            to={isPolish ? "omnie" : "aboutme"}
            onClick={props.onClick}
            className="main-nav__item"
          >
            {isPolish ? "o mnie" : "about me"}
          </NavLink>
          <NavLink
            to={isPolish ? "kontakt" : "contact"}
            onClick={props.onClick}
            className="main-nav__item"
          >
            {isPolish ? "kontakt" : "contact"}
          </NavLink>
          <NavLink
            to={isPolish ? "publikacje" : "publications"}
            onClick={props.onClick}
            className="main-nav__item"
          >
            {isPolish ? "publikacje" : "publications"}
          </NavLink>
        </nav>
      </div>
    </>
  );
};

export default MainNav;
