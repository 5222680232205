import { createContext } from "react";

export const PageContext = createContext({
    isMobileNav: false,
    showMobileNav: () => {},
    hideMobileNav: () => {},
    handleAnimationOfToggleButton: () => {},
    yUp: 0,
    yDown: 0,
    rotateLeft: 0,
    rotateRight: 0,
});