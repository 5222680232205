import React, { useContext, useEffect, useState } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { useParams, useNavigate } from "react-router-dom";

import languageSwitch from "../assets/languageSwitch";
import MobileNav from "./MobileNav";
import MobileHeader from "./MobileHeader";
import DesktopHeader from "./DesktopHeader";
import { PageContext } from "../store/context";

const Header = () => {
  const [isMobile, setIsMobile] = useState(true);
  const pageCtx = useContext(PageContext);
  const params = useParams(false);
  const navigate = useNavigate();
  const { scrollY } = useScroll();

  useEffect(() => {
    setIsMobile(window.innerWidth < 850);
    window.addEventListener("resize", handleResize);
  }, []);

  function handleResize() {
    setIsMobile(window.innerWidth < 850);
  }

  const { lang, info, detail } = params;
  const isPolish = lang === "pl";

  const opacityHeader = useTransform(
    scrollY,
    [0, 75],
    ["rgba(63, 96, 149, 0.6)", "rgba(63, 96, 149, 0)"]
  );

  let updatedLang, updatedInfo, updatedDetail, updatedPath;

  const switchToEnglish = () => {
    updatedLang = "en";
    updatedPath = `/${updatedLang}`;
    if (info) {
      updatedInfo = languageSwitch.find((element) => element.pl === info);
    }
    if (detail) {
      updatedDetail = languageSwitch.find((element) => element.pl === detail);
    }
    if (updatedInfo && !updatedDetail) {
      updatedInfo = updatedInfo.en;
      updatedPath = updatedPath + `/${updatedInfo}`;
      navigate(updatedPath);
    }
    if (updatedInfo && updatedDetail) {
      updatedInfo = updatedInfo.en;
      updatedDetail = updatedDetail.en;
      updatedPath = updatedPath + `/${updatedInfo}`;
      updatedPath = updatedPath + `/${updatedDetail}`;
      navigate(updatedPath);
    }
    navigate(updatedPath);
  };

  const switchToPolish = () => {
    updatedLang = "pl";
    updatedPath = `/${updatedLang}`;
    if (info && !detail) {
      updatedInfo = languageSwitch.find((element) => element.en === info);
    }
    if (detail) {
      updatedInfo = languageSwitch.find((element) => element.en === info);
      updatedDetail = languageSwitch.find((element) => element.en === detail);
    }
    if (updatedInfo && !updatedDetail) {
      updatedInfo = updatedInfo.pl;
      updatedPath = updatedPath + `/${updatedInfo}`;
      navigate(updatedPath);
    }
    if (updatedInfo && updatedDetail) {
      updatedInfo = updatedInfo.pl;
      updatedDetail = updatedDetail.pl;
      updatedPath = updatedPath + `/${updatedInfo}`;
      updatedPath = updatedPath + `/${updatedDetail}`;
      navigate(updatedPath);
    }
    navigate(updatedPath);
  };

  return (
    <>
      <motion.div
        style={{
          backgroundColor: pageCtx.isMobileNav
            ? "rgba(28,43,69,0.9)"
            : opacityHeader,
          backdropFilter: pageCtx.isMobileNav ? "blur(10px)" : "none",
        }}
        className="header"
      >
        {isMobile && (
          <MobileHeader
            isPolish={isPolish}
            switchToEnglish={switchToEnglish}
            switchToPolish={switchToPolish}
          />
        )}

        {!isMobile && (
          <DesktopHeader
            isPolish={isPolish}
            switchToEnglish={switchToEnglish}
            switchToPolish={switchToPolish}
          />
        )}
      </motion.div>

      {pageCtx.isMobileNav && (
        <MobileNav
          variants={{
            hidden: { y: -30 },
            visible: { y: 0 },
          }}
          initial="hidden"
          animate="visible"
          exit="hidden"
        />
      )}
    </>
  );
};

export default Header;
