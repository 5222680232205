import React, { useContext } from 'react';
import { motion } from 'framer-motion';
import { PageContext } from '../store/context';


const ToggleButton = () => {
  const pageCtx = useContext(PageContext);

  function toggleMobileNavHandler() {
    if (pageCtx.isMobileNav === true) {
      pageCtx.hideMobileNav();
    } else {
      pageCtx.showMobileNav();
    }
  }

  return (
    <button
    
          onClick={() => { toggleMobileNavHandler(); pageCtx.handleAnimationOfToggleButton(); }}
          className="header-toggle-button"
        >
          <motion.span className="header-toggle-button__bar"
          animate={{y: pageCtx.yDown, rotate: pageCtx.rotateLeft}}
          ></motion.span>
          <motion.span className="header-toggle-button__bar"
          animate={{y: pageCtx.yUp, rotate: pageCtx.rotateRight}}
          ></motion.span>
        </button>
  )
}

export default ToggleButton;
