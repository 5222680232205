export default [
    {pl: 'portfolio', en: 'portfolio'},
    {pl: 'obrazy', en: 'paintings'},
    {pl: 'rzeki', en: 'rivers'},
    {pl: 'drzewa', en: 'trees'},
    {pl: 'omnie', en: 'aboutme'},
    {pl: 'kontakt', en: 'contact'},
    {pl: 'publikacje', en: 'publications'},
    {pl: 'IkonostasMiasto', en: 'IconostasisCity'},
    {pl: 'Labedzie', en: 'Swans'},
    {pl: 'Miasto', en: 'City'},
    {pl: 'MuralLudkaDrzewolazka', en: 'Mural'},
    {pl: 'Poliptyki2004', en: 'Polyptyches2004'},
    {pl: 'Poliptyki2020', en: 'Polyptyches2020'},
    {pl: 'Polowanie', en: 'Hunting'},
    {pl: 'KoncertUPanaZula', en: 'ConcertAtMrThug'},
    {pl: 'Wierzba', en: 'Willow'},
    {pl: 'RajNaZiemi', en: 'ParadiseOnEarth'},
    {pl: 'RajNaZiemiObrazy', en: 'ParadiseOnEarthPaintings'},
    {pl: 'LasLegowy', en: 'RiparianForestReserve'},
    {pl: 'ChciwoscMiasta', en: 'GreedOfTheCity'},
    {pl: 'DabJacek', en: 'DefenceOfTheJacekOak'},
    {pl: 'DomekNaJesionie', en: 'TreehouseonAsh'},
    {pl: '365Drzew', en: '365Trees'},
    {pl: 'SiostryRzeki', en: 'RiverSisters'},
    {pl: 'HejLudzieWycieliNamDrzewa', en: 'HeyPeopleTheyCutOutOurTrees'},
    {pl: 'MatkiPolkiNaWyrebie', en: 'PolishMothersOnTreeStumps'},
    {pl: 'OratoriumNaPily', en: 'OratoriumOnSaws'},
    {pl: 'RajNaZiemi', en: 'ParadiseOnEarth'},
    {pl: 'NiciZParkingu', en: 'BangGoesTheParking'},
    {pl: 'RajNaPlantach', en: 'ParadiseOnPlanty'},
    {pl: 'SuknieLekowe', en: 'MeadowDresses'},
    {pl: 'WarkoczeBialki', en: 'BialkaBraids'},
    {pl: 'WodnaMasaKrytyczna', en: 'AquaticCriticalMass'},
    {pl: '6Rzek', en: '6Rivers'},
    {pl: 'Ryby', en: 'Fishes'},
    {pl: 'ModraszekKolektyw', en: 'AlconBlueCollective'},
    {pl: 'ulSmolensk', en: 'SmolenskStreet'},
    {pl: 'ModaNaRzeki', en: 'FashionForRivers'},
    {pl: 'TrocNaOlbnie', en: 'SeeTroutOnOlbin'},
    
];