import React from 'react';

const LanguageButton = ({ isPolish , onSwitchToEnglish,  onSwitchToPolish}) => {
  return (
    <p className="header-language">
          {isPolish ? (
            <button className="header-language__option" onClick={onSwitchToEnglish}>english</button>
          ) : (
            <button className="header-language__option" onClick={onSwitchToPolish}>polski</button>
          )}
        </p>
  )
}

export default LanguageButton;
